import * as bootstrap from 'bootstrap';

window.addEventListener('load', function () {
	const popoverTriggerList = document.querySelectorAll(
		'[data-bs-toggle="popover"]'
	);
	const popoverList = [...popoverTriggerList].map(
		(popoverTriggerEl) => new bootstrap.Popover(popoverTriggerEl)
	);
	if (document.querySelectorAll('.accordion-auto')[0]) {
		document
			.querySelectorAll('.accordion-auto')
			.forEach(function (element, index) {
				element
					.querySelectorAll('.collapse')
					.forEach(function (collapseElement) {
						new bootstrap.Collapse(collapseElement, {
							parent: element,
							toggle: false
						});
					});
				element
					.querySelectorAll('.collapse-toggle')
					.forEach(function (toggleElement) {
						toggleElement.addEventListener('click', function (e) {
							e.preventDefault();
							e.stopPropagation();
							let collapsed = e.target
								.closest('.collapse-toggle')
								.classList.contains('collapsed');

							this.closest('.accordion-auto')
								.querySelectorAll('.collapse-toggle')
								.forEach(function (collapseToggleElement) {
									collapseToggleElement.classList.add(
										'collapsed'
									);
								});
							collapsed &&
								e.target
									.closest('.collapse-toggle')
									.classList.remove('collapsed');
							this.closest('.accordion-item')
								.querySelectorAll('.collapse')
								.forEach(function (collapseElement) {
									const collapse =
										bootstrap.Collapse.getInstance(
											collapseElement
										);
									collapse.toggle();
								});
						});
					});
			});
	}
	if (document.querySelectorAll('.tabs-auto')[0]) {
		document
			.querySelectorAll('.tabs-auto')
			.forEach(function (element, index) {
				var id = 'tabsAuto-' + index;
				element
					.querySelectorAll('.tab-pane')
					.forEach(function (tabPane, index) {
						tabPane.setAttribute('id', id + '-' + index);
					});
				element
					.querySelectorAll('.tabs-auto-nav .nav-link')
					.forEach(function (tabElement, index) {
						tabElement.setAttribute(
							'data-bs-target',
							'#' + id + '-' + index
						);
					});
			});
	}
	var scrollTopBtn = document.querySelector('.scroll-top');
	scrollTopBtn &&
		scrollTopBtn.addEventListener('click', function () {
			window.scrollTo({ top: 0, behavior: 'smooth' });
		});
	window.addEventListener('scroll', function (event) {
		const scrollTop = document.querySelector('.scroll-top');
		if (window.scrollY > 200) {
			scrollTop.classList.add('show');
		} else {
			scrollTop.classList.remove('show');
		}
	});
});
window.bootstrap = bootstrap;
